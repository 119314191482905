<template>
  <ul class="groups-list" v-if="list.length !== null">
    <li v-for="(item, index) in list" :key="index">
      <a class="item" href="javascript:void(0)" :title="item.title" @click="routeGo(item.id, item.joinTime)">
        <i class="avatar"><b :style="{backgroundImage: `url(${item.pic})`}"></b></i>
        <span class="info">
          <em class="title">{{ item.title }} </em>
          <em class="details">
            <strong class="class" :title="t('components.groupsList.classTitle')">
              <b class="icon"><img src="../assets/img/admin.svg" alt=""></b>
              <b class="text" v-if="item.type === 1">{{ t('components.groupsList.creator') }}</b>
              <b class="text" v-else-if="item.type === 2">{{ t('components.groupsList.admin') }}</b>
              <b class="text" v-else>{{ t('components.groupsList.abnormal') }}</b>
            </strong>
            <strong class="total" :title="t('components.groupsList.totalMember')">
              <b class="icon"><img src="../assets/img/members.svg" alt=""></b>
              <b class="text">{{ item.totalMember }}</b>
            </strong>
            <strong class="join" :title="t('components.groupsList.recordTotal')">
              <b class="icon"><img src="../assets/img/graph.svg" alt=""></b>
              <b class="text">{{ item.percent }}</b>
            </strong>
            <strong class="join" :title="t('components.groupsList.joinTime')" v-if="item.joinTime != ''">
              <b class="icon"><img src="../assets/img/calendar.svg" alt=""></b>
              <b class="text">{{ item.joinTime }}</b>
            </strong>
          </em>
        </span>
      </a>
    </li>
  </ul>
  <p class="no-data" v-else>{{ t('components.noData') }}</p>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
  name: 'GroupsList',
  props: {
    list: {
      type: Array,
      default() {
        return []
      }
    }
  },
  setup() {
    const { t } = useI18n()
    const Store = useStore()
    const Router = useRouter()

    const routeGo = (id, joinTime) => {
      Store.commit('set', {
        gid: id,
        joinTime: joinTime
      })
      Store.commit('setPanelNav', {
        groupName: '',
        groupAvatar: ''
      })
      Router.push({ name: 'Config' })
    }

    return { t, routeGo }
  }
}
</script>

<style lang="scss" scoped>
.groups-list {
  li {
    margin-bottom: 20px;
    .item {
      display: flex;
      width: 100%;
      height: 100px;
      padding: 0 20px;
      border-radius: 6px;
      background: #fff;
      align-items: center;
      box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.2);
      .avatar {
        b {
          display: block;
          width: 60px;
          height: 60px;
          border-radius: 50%;
          background: no-repeat center center / cover gray;
        }
      }
      .info {
        overflow: hidden;
        padding-left: 20px;
        b {
          display: table-cell;
          vertical-align: middle;
        }
        .title {
          display: block;
          font-size: 16px;
          font-weight: bold;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .details {
          display: flex;
          font-size: 14px;
          margin-top: 7px;
          white-space: nowrap;
          strong {
            display: flex;
            align-items: center;
            margin-right: 30px;
            .icon {
              width: 18px;
            }
            .text {
              padding-left: 5px;
            }
          }
        }
      }
    }
  }
}

.groups-list::after {
  content: "020";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
</style>
