<template>
  <div class="app panel">
    <Navigation/>
    <div class="panel-content">
      <div class="panel-content-in">
        <div class="head">
          <p class="title">{{ t('panel.title') }}</p>
        </div>
        <template v-if="!loading">
          <GroupsList :list="list"/>
        </template>
        </div>
    </div>
  </div>
</template>

<script>
import Navigation from '../../components/PanelNav.vue'
import GroupsList from '../../components/GroupsList.vue'
import { useI18n } from 'vue-i18n'
import { reactive, toRefs, onMounted } from 'vue'
import { useDialog } from '../../utils/dialog.js'
import { groupsListApi } from '../../api/index.js'

export default {
  name: 'Panel',
  components: {
    Navigation,
    GroupsList
  },
  setup() {
    const { t } = useI18n()
    const Dialog = useDialog()
    const data = reactive({
      loading: true,
      list: []
    })

    onMounted(() => {
      init()
    })

    const init = () => {
      Dialog({ type: 'loading' })

      groupsListApi().then(succ => {
        if (succ.code === 10000) {
          data.list = succ.data.list
        }
      }).finally(() => {
        data.loading = false
        Dialog({ remove: true })
      })
    }

    return { ...toRefs(data), t }
  }
}
</script>

<style lang="scss" scoped>
.panel {
  &-content {
    left: 0 !important;
    padding-top: 0 !important;
    &-in {
      padding-top: 0 !important;
      background: transparent !important;
      box-shadow: 0 0 0 0 transparent !important;
      .head {
        margin: 30px 0;
        .title {
          font-size: 20px;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
